import api from "../api/api";

export function getCurrentUser() {
  const userStr = localStorage.getItem("user-info");
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export function getToken() {
  return localStorage.getItem("token") || null;
}

export function setCurrentUser(user) {
  localStorage.setItem("user-info", JSON.stringify(user));
}
export function setCurrentAdmin(user) {
  localStorage.setItem("staff", JSON.stringify(user));
}
export function setClearanceOfficer(user) {
  localStorage.setItem("clearance", JSON.stringify(user));
}
export function setAdminOfficer(user) {
  localStorage.setItem("admin", JSON.stringify(user));
}
export function setBursary(user) {
  localStorage.setItem("bursary", JSON.stringify(user));
}

export function removeUser() {
  localStorage.removeItem("user-info");
  localStorage.removeItem("user-token");
  localStorage.removeItem("admin-token");
  localStorage.removeItem("staff");
  localStorage.clear();
}

export function logOutUser() {
  api.get(`/authentication/logout`);
}
