import moment from "moment";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/DSCE.png";
import logo2 from "../assets/images/uniben_logo.png";
import avatar from "../assets/images/user.png";
import useDetails from "../hooks/useDetails";
import { getCurrentUser } from "../utils/authService";

function HostelInvoice() {
  const [student, setStudent] = useState();
  const [passport, setPassport] = useState();
  const [reference, setReference] = useState();
  const [amount, setAmount] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [paymentName, setPaymentName] = useState();
  const [general, setGeneral] = useState();
  const navigate = useNavigate();

  const { data } = useDetails();
  const photo = data?.photo;

  const { state } = useLocation();
  const user = getCurrentUser();

  const printPaymentReceipt = () => {
    window.print();
  };
  const onImageError = (e) => {
    e.target.src = avatar;
  };

  useEffect(() => {
    //   getPassport();
    console.log(state);
    setGeneral(state.invoice);
    setStudent(state?.invoice?.application);
    setReference(state?.invoice?.payment_ref);
    setAmount(state?.invoice?.amount);
    setCreatedAt(state?.invoice?.generated_date);
    setPassport(state?.invoice?.application?.photo);
  }, []);

  return (
    <>
      <div className="print-button">
        <button onClick={() => navigate(-1)} className="btn-danger">
          Go back
        </button>
        <button className="btn-primary" onClick={printPaymentReceipt}>
          Print
        </button>
      </div>
      {data && state && (
        <div className="receipt">
          <div className="contents">
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">
                  -
                </h3>
              </div>
              <h6 className="text-center">In Affiliation with</h6>
              <div className="school-title">
                <img src={logo2} alt="DSP" className="logo" />
                <h3 className="name">University of Benin</h3>
              </div>
              <h4 className="subheader">
                Hostel Payment Slip <span>{reference}</span>
              </h4>
            </div>
            <div className="receipt-body">
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Base Data
              </h4>
              <div className="body-header">
                <div className="passport">
                  <img
                    src={photo ? photo : avatar}
                    alt="Passport"
                    onError={onImageError}
                  />
                </div>
                <div className="user-details">
                  <div>
                    <h3 className="user-details-option">Name:</h3>
                    <h3 className="user-details-info">
                      {data?.surname} {data?.othername}{" "}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">
                      Matriculation Number:
                    </h3>
                    <h3 className="user-details-info">{data?.matric_no}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Course:</h3>
                    <h3 className="user-details-info">{data?.department}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Email:</h3>
                    <h3 className="user-details-info">{data?.email}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Entry Mode:</h3>
                    <h3 className="user-details-info">{data?.entry_mode}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Study Mode:</h3>
                    <h3 className="user-details-info">{data?.study_mode}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Session:</h3>
                    <h3 className="user-details-info">{data?.entry_session}</h3>
                  </div>
                </div>
                <div className="qr-code">
                  {data && <QRCode value={reference} size={90} />}
                </div>
              </div>
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Payment Data
              </h4>

              <div className="body-info">
                <div>
                  <h3 className="user-details-option">Amount Paid:</h3>
                  <h3 className="user-details-info">₦{amount}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Hostel Name:</h3>
                  <h3 className="user-details-info">
                    {general?.room_type?.name}
                  </h3>
                </div>
                <div>
                  <h3 className="user-details-option">
                    Number of Occupant Name:
                  </h3>
                  <h3 className="user-details-info">
                    {general?.room_type?.num_of_occupants}
                  </h3>
                </div>
                <div>
                  <h3 className="user-details-option">Date of Payment:</h3>
                  <h3 className="user-details-info">
                    {moment(createdAt).format("DD/MM/YYYY hh:mm")}
                  </h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Description:</h3>
                  <h3 className="user-details-info"> Hostel Payment</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Invoice ID:</h3>
                  <h3 className="user-details-info">{reference}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Gateway:</h3>
                  <h3 className="user-details-info">PayStack</h3>
                </div>
              </div>
            </div>
            <div className="receipt-footer">
              <p>Date: {moment(student?.update).format("DD/MM/YYYY hh:mm")}</p>
              <p>
                {reference} - Online Payment Slip p6389714479930 - Page 1 of 1
              </p>
            </div>
          </div>
        </div>
      )}
      {!data && !state && (
        <center>
          <div
            class="preloader pl-size-xs"
            style={{ marginRight: "5px", marginTop: "100px" }}
          >
            <div class="spinner-layer pl-red-grey">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </center>
      )}
    </>
  );
}

export default HostelInvoice;
