import { useEffect, useState } from "react";
import api from "../../api/api";
import Footer from "../../components/Footer";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

const CourseModification = () => {
  const [level, setLevel] = useState("");
  const [matNo, setMatNo] = useState("");
  const [pagination, setPagination] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState();
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [affiliate, setAffiliate] = useState("MOSOGAR");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surname: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  //   const token = user?.access;

  const onPageChange = (event) => {
    console.log(event, "<<<<<<<");
    setPagination(event.page); // API pagination typically starts at 1
  };

  const onRowsChange = (event) => {
    setPagination(1); // Reset to the first page when changing page size
    setPageSize(event.value);
  };

  const getListOfCourseRegistration = async () => {
    // if (!level && !matNo) return;
    try {
      setLoading(true);
      const { data } = await api.get(
        `/courses/read-unique-registrations?page=${
          pagination + 1
        }&registration_level__icontains=${level}&student__matric_no__icontains=${matNo}&student__affilated_school_name__icontains=${affiliate}`
      );
      console.log(data, "course-reg");
      setTotalRecords(data.count);
      setRegistrations(data.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(_filters, "_filterrr");

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const actions = (_data) => {
    let { student_id } = _data;
    return (
      <button
        className="btn dspg-dark"
        onClick={() =>
          navigate("/course-modification/info", { state: student_id })
        }
      >
        View details
      </button>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    getListOfCourseRegistration();
  }, [pagination, level, matNo, affiliate]);
  return (
    <div className="page-wrapper">
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Registration</li>
                  </ol>
                </div>
                <h4 class="page-title">View Student Course Registrations</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          {/**Affiliate section */}
                          <div className="d-flex justify-content-end">
                            <div className="w-25 d-flex flex-column ">
                              <label>CHANGE AFFILIATE SCHOOL</label>
                              <select
                                className="form-control"
                                value={affiliate}
                                onChange={(e) => setAffiliate(e.target.value)}
                              >
                                <option value="MOSOGAR">
                                  AJURU UNDERGRADUATE
                                </option>
                                <option value="UNIBEN">
                                  UNIBEN UNDERGRADUATE
                                </option>
                                <option value="DELSU">
                                  DELSU UNDERGRADUATE
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="container">
                            <h5 className="mb-3">Choose options below</h5>
                            <div class="form-group row">
                              <div class="col-md-6">
                                <label>Select Registration Level</label>
                                <select
                                  class="form-control mb-3"
                                  value={level}
                                  onChange={(e) => setLevel(e.target.value)}
                                >
                                  <option></option>
                                  <option>100</option>
                                  <option>200</option>
                                  <option>300</option>
                                  <option>400</option>
                                  <option>500</option>
                                </select>
                                {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                              </div>
                              <div class="col-md-6">
                                <label>Enter Matric Number</label>
                                <input
                                  class="form-control mb-3"
                                  value={matNo}
                                  onChange={(e) => setMatNo(e.target.value)}
                                />
                                {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <DataTable
                                value={registrations}
                                // selectionMode={rowClick ? null : "checkbox"}
                                // selection={"selectedCourses"}
                                // onSelectionChange={"handleSelectionChange"}
                                dataKey="id"
                                tableStyle={{ minWidth: "50rem" }}
                                loading={loading}
                                header={header}
                                filters={filters}
                              >
                                <Column
                                  header="First Name"
                                  field="othername"
                                ></Column>
                                <Column
                                  header="Last Name"
                                  field="surname"
                                ></Column>
                                <Column
                                  field="matric_no"
                                  header="Matric. Number"
                                ></Column>
                                <Column
                                  field="registration_level"
                                  header="Registration Level"
                                ></Column>
                                <Column
                                  field="session"
                                  header="Registration Session"
                                ></Column>
                                <Column
                                  header="Actions"
                                  body={actions}
                                ></Column>
                              </DataTable>
                              <Paginator
                                first={pagination}
                                rows={1}
                                totalRecords={totalRecords}
                                onPageChange={onPageChange}
                                onRowsChange={onRowsChange}
                                template=" PrevPageLink CurrentPageReport NextPageLink"
                              />
                            </div>

                            {/* {selectedCourses?.length ? (
                                <div className="bg-light col-lg-9">
                                  <table className="mt-3 col-lg-12">
                                    <thead className="dspg-dark">
                                      <th>Code</th>
                                      <th>Course</th>
                                      <th>Unit</th>
                                      <th>Semester</th>
                                    </thead>
                                    {selectedCourses?.map((list) => (
                                      <tbody>
                                        <td>{list.code}</td>
                                        <td>{list.title}</td>
                                        <td>{list.unit}</td>
                                        <td>{list.semester}</td>
                                      </tbody>
                                    ))}
                                    <tfoot>
                                      <tr>
                                        <td className="h5">Total Units</td>
                                        <td></td>
                                        <td className="h5">{totalUnits}</td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                ""
                              )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CourseModification;
